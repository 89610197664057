import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, Label } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import images
import vector2 from '../../assets/images/vector2.png';
import logo from '../../assets/images/logo-laina-hub.png';

import { ISetPwdFormFields } from '../../store/types';
import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
// services
import { setPassword } from '../../services';
import { printEDCLog } from '../../helpers';
import toastr from 'toastr';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

/**
 * Authentication/Login component.
 *
 * @remarks
 * Page where data monitor can log in
 *
 * @component Login
 * @category Page
 */
const Login = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const userId = params.id as string;
  const { dashTitle } = useData();
  const { error, setError, clearState } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  // handleValidSubmit
  async function handleValidSubmit(values: ISetPwdFormFields) {
    clearState();
    SetIsSubmitting(true);
    if (values.password != values.passwordConfirmation) {
      setError('Password confirmation does not match');
      SetIsSubmitting(false);
      return;
    }
    if (values.password.trim().length == 0) {
      setError('Password can not be only space');
      SetIsSubmitting(false);
      return;
    }
    try {
      const res = await setPassword(userId, values.password);
      printEDCLog('set Password success : setPassword', res);

      toastr.success('Password has been set successfully.', 'Success');
      navigate('/studycoordinator');
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.data) {
        setError(err.response.data.message ?? 'Something wrong!');
      }
    }
    SetIsSubmitting(false);
    if (!mounted.current) return;
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <FontAwesomeIcon className="fa-2xl" icon={'home'} />
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">Set Password</h5>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        password: '',
                        passwordConfirmation: '',
                      }}
                      validationSchema={Yup.object().shape({
                        password: Yup.string().required('Enter Password'),
                        passwordConfirmation: Yup.string().required('Re-enter Password'),
                      })}
                      onSubmit={(values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          {/* <div className="form-group">
                            <Label for="email" className="form-label">
                              Your Email
                            </Label>
                            <Field
                              name="email"
                              id="email"
                              type="text"
                              required
                              disabled
                              placeholder="Enter Your Email"
                              className="form-control"
                              value={'Your email'}
                            />
                          </div>

                          <div className="form-group">
                            <Label for="username" className="form-label">
                              Your Username
                            </Label>
                            <Field
                              name="username"
                              id="username"
                              type="text"
                              required
                              disabled
                              placeholder="Enter Your Username"
                              className="form-control"
                              value={'username'}
                            />
                          </div> */}

                          <div className="form-group">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <Field
                              name="password"
                              id="password"
                              type="password"
                              required
                              placeholder="Enter Password"
                              className={
                                'form-control' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="passwordConfirmation" className="form-label">
                              Password Confirmation
                            </Label>
                            <Field
                              name="passwordConfirmation"
                              id="passwordConfirmation"
                              type="password"
                              required
                              placeholder="Enter Password Confirmation"
                              className={
                                'form-control' +
                                (errors.passwordConfirmation && touched.passwordConfirmation
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name="passwordConfirmation"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Set Password
                              {isSubmitting && <Spinner className="spinner" />}
                            </button>
                          </div>

                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a className="login-notice" href={'https://prod.hub.lainaent.com/'}>
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
