import React, { useEffect, useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container, Spinner, Label } from 'reactstrap';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// action
import {} from '../../services';

// import images
import vector2 from '../../assets/images/vector2.png';
import logo from '../../assets/images/logo-laina-hub.png';

import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getResetPasswordInfo, resetPasswordWithCode } from '../../services';
import { AxiosError } from 'axios';
import toastr from 'toastr';

/**
 * Authentication/ResetPassword component.
 *
 * @remarks
 * Page where data monitor can reset password
 *
 * @component ResetPassword
 * @category Page
 */
const ResetPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { dashTitle } = useData();
  const { error, setError, success, setSuccess, clearState } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [pwdError, setPwdError] = useState<string>('');

  useEffect(() => {
    initForm();
  }, []);

  // handleValidSubmit
  async function handleValidSubmit(values: Record<string, any>) {
    console.log(values);

    clearState();
    SetIsSubmitting(true);
    let res2: any;
    setPwdError('');
    if (values.password !== values.password_confirmation) {
      setPwdError('Confirm Password does not match');
      SetIsSubmitting(false);
      return;
    }
    try {
      res2 = await resetPasswordWithCode(values.username, values.verificationCode, values.password);
      console.log(res2);
      if (res2 && res2.data?.success) {
        setSuccess('The password has been reset.');
        toastr.success('Password has been reset successfully.', 'Success');
        navigate('/studycoordinator');
      } else {
        setPwdError(res2.data.message!);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.data) {
        setError(err.response.data?.message);
      }
    }
    SetIsSubmitting(false);
  }

  const initForm = async () => {
    const res: any = await getResetPasswordInfo(params.token as string);

    if (res && res.data) {
      setUsername(res.data.username);
      setCode(res.data.code);
    }
  };

  const handleClearState = () => {
    clearState();
  };

  const titleParts = dashTitle.split('(');

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <FontAwesomeIcon className="fa-2xl" icon={'home'} />
        </Link>
      </div>
      <div className="account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p>{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {error}
                      </Alert>
                    ) : null}
                    {pwdError && pwdError ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {pwdError}
                      </Alert>
                    ) : null}
                    {success ? (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {success}
                      </Alert>
                    ) : null}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        username: username,
                        verificationCode: '',
                        password: '',
                        password_confirmation: '',
                      }}
                      validationSchema={Yup.object().shape({
                        username: Yup.string().required('Enter Username'),
                        verificationCode: Yup.string().required('Enter Code'),
                        password: Yup.string().required('Enter Password'),
                        password_confirmation: Yup.string().required('Enter Password Confirmation'),
                      })}
                      onSubmit={(values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          <h3 className="text-center mb-5">Set Password</h3>

                          <div className="form-group">
                            <Label for="username" className="form-label">
                              Username
                            </Label>
                            <Field
                              name="username"
                              type="input"
                              required
                              disabled
                              placeholder="Enter Username"
                              value={username}
                              className={
                                'form-control' +
                                (errors.username && touched.username ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="verificationCode" className="form-label">
                              Code
                            </Label>
                            <Field
                              name="verificationCode"
                              type="input"
                              required
                              placeholder="Enter Code"
                              className={
                                'form-control' +
                                (errors.verificationCode && touched.verificationCode
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name="verificationCode"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="password" className="form-label">
                              New Password
                            </Label>
                            <Field
                              name="password"
                              type="password"
                              required
                              placeholder="Enter new password"
                              className={
                                'form-control' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="password_confirmation" className="form-label">
                              Confirm Password
                            </Label>
                            <Field
                              name="password_confirmation"
                              type="password"
                              required
                              placeholder="Enter confirm password"
                              className={
                                'form-control' +
                                (errors.password_confirmation && touched.password_confirmation
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name="password_confirmation"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-login w-md waves-effect waves-light save-btn"
                                disabled={isSubmitting}
                                type="submit"
                              >
                                Reset
                                {isSubmitting && <Spinner className="spinner" />}
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{' '}
                  <Link
                    to="/studycoordinator"
                    className="font-weight-medium text-darkblue"
                    onClick={handleClearState}
                  >
                    Login
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
