import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { getSites, getStudies } from '../../services';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteSiteModal from '../../components/DeleteSiteModal';
import { useData } from '../../context/DataContext';

/**
 * Study Page component.
 *
 * @remarks
 * Study Page that manages all locations
 *
 * @component Study Page
 * @category Page
 */

const PageHeder = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
`;

const StudyDetail = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { allSites, setAllSites } = useData();
  const [sites, setSites] = useState<Array<any>>();
  const [study, setStudy] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>();
  const studyId = params.id as string;

  const breadCrumbItems = [
    {
      link: '/studies',
      text: 'Study Management',
    },
    {
      link: '/studies',
      text: `Site Locations Of ${study?.study_name ?? ''}`,
    },
  ];

  useEffect(() => {
    getSitesData();
  }, []);

  const handleEdit = (site: any) => {
    navigate(`/sites/${studyId}/${site._id}`);
  };

  const handleAddNew = () => {
    navigate(`/sites/${studyId}/create`);
  };

  const getSitesData = async () => {
    const studiesRes = await getStudies();
    if (studiesRes?.data?.studies) {
      const currentStudy = studiesRes.data.studies?.find((study: any) => study._id == studyId);
      setStudy(currentStudy);
      const sitesRes = await getSites();
      if (sitesRes?.data?.sites) {
        setSites(
          sitesRes.data.sites.filter(
            (site: any) => site.study_id == currentStudy._id || site.study?._id == currentStudy._id,
          ),
        );
        setAllSites(sitesRes?.data?.sites);
      }
    }
  };

  const deleteToggleModal = (id: string) => {
    setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid className="container-page">
          <Breadcrumb items={breadCrumbItems} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PageHeder>
                    <Button color="primary" onClick={() => handleAddNew()}>
                      Add New Site Location
                    </Button>
                  </PageHeder>
                  <div className="row">
                    <table className="table sortable-table">
                      <thead>
                        <tr>
                          <th>Site ID</th>
                          <th>Site Name</th>
                          <th>Site Type</th>
                          <th>Address</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sites?.map((data: any) => {
                          return (
                            <tr key={data._id} className="table-light">
                              <td>{data.site_id}</td>
                              <td>{data.site_name}</td>
                              <td>{data.site_type}</td>
                              <td>{data.address}</td>
                              <td>
                                <Button
                                  color="primary"
                                  id={`edit-btn-${data._id}`}
                                  className="mr-1 mb-1"
                                  onClick={() => handleEdit(data)}
                                >
                                  <i className="fa fa-pencil" />
                                </Button>
                                <Button
                                  color="danger"
                                  className="mr-1 mb-1"
                                  id={`delete-btn-${data._id}`}
                                  onClick={() => deleteToggleModal(data._id)}
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`edit-btn-${data._id}`}
                                >
                                  Edit Site
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`delete-btn-${data._id}`}
                                >
                                  Delete Site
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <DeleteSiteModal
        modal={deleteModal}
        id={deleteId}
        deleteToggleModal={deleteToggleModal}
        getSites={getSitesData}
      />
    </>
  );
};

export default StudyDetail;
