import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { useData } from '../../context/DataContext';

const Footer = () => {
  const { footer_text, footer_text_right } = useData();

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{footer_text}</Col>
            <Col md={6} className="text-right">
              {footer_text_right}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
