import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, Label } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import images
import vector2 from '../../assets/images/vector2.png';
import logo from '../../assets/images/logo-laina-hub.png';

import { IParticipantLoginFormFields } from '../../store/types';
import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
// services
import { validateParticipant } from '../../services';
import { printEDCLog } from '../../helpers';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

/**
 * Authentication/Login component.
 *
 * @remarks
 * Page where data monitor can log in
 *
 * @component Login
 * @category Page
 */
const Login = (): JSX.Element => {
  const { dashTitle } = useData();
  const { error, setError, clearState } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  // handleValidSubmit
  async function handleValidSubmit(values: IParticipantLoginFormFields) {
    clearState();
    SetIsSubmitting(true);
    try {
      const res = await validateParticipant(values.participantId);
      printEDCLog('success : validateParticipant', res);

      if (res && res.data) {
        if (res.data.success && res.data?.trialAddress) {
          if (res.data.passwordStatus === 'needed') {
            document.location.href =
              'https://' + res.data.trialAddress + `/study/setPassword/${values.participantId}`;
          } else {
            document.location.href =
              'https://' + res.data.trialAddress + `/study/enterPassword/${values.participantId}`;
          }
        } else {
          setError(res.data?.message);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.data) {
        setError(err.response.data.message ?? 'Username or password is not correct');
      }
    }
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  const handleClearState = () => {
    clearState();
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <FontAwesomeIcon className="fa-2xl" icon={'home'} />
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">Participant Login</h5>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        participantId: '',
                      }}
                      validationSchema={Yup.object().shape({
                        participantId: Yup.string().required('Enter Participant ID'),
                      })}
                      onSubmit={(values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="form-group">
                            <Label for="participantId" className="form-label">
                              Please enter the survey ID or participant ID that has been assigned to
                              you
                            </Label>
                            <Field
                              name="participantId"
                              id="participantId"
                              type="input"
                              required
                              placeholder=""
                              className={
                                'form-control' +
                                (errors.participantId && touched.participantId ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="participantId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Sign In
                              {isSubmitting && <Spinner className="spinner" />}
                            </button>
                          </div>

                          <div className="mt-3">
                            <Link
                              to="/studycoordinator"
                              onClick={handleClearState}
                              className="btn btn-light-login btn-block waves-effect waves-light save-btn"
                            >
                              I have a coordinator username
                            </Link>
                          </div>

                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a className="login-notice" href={'https://prod.hub.lainaent.com/'}>
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}

                          {/* <div className="mt-4 text-center">
                            <Link
                              to="/forgot-password"
                              className="text-muted"
                              onClick={handleClearState}
                            >
                              <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                            </Link>
                          </div> */}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
