import React, { useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';
import { Cookies } from 'react-cookie';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

// import { printEDCLog } from '../../helpers';

const cookies = new Cookies();

const Layout = () => {
  const location = useLocation();
  const { leftSideBarType, setLeftSideBarType } = useData();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/') {
      // printEDCLog('save hubRedirectUrl when url entered on browser', location.pathname);
      cookies.set('hubRedirectUrl', location.pathname, { path: '/' });
    }
  }, []);

  useEffect(() => {
    document.getElementById('preloader')!.style.display = 'none';
    document.getElementById('status')!.style.display = 'none';
    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (leftSideBarType) {
      setLeftSideBarType(leftSideBarType);
    }
  }, [location.pathname, setLeftSideBarType, leftSideBarType]);

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
