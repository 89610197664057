import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { Link, useLocation } from 'react-router-dom';

import { MenuItem } from '../../constants/types';
import { useData } from '../../context/DataContext';

type IntlTooltipProps = 'tooltip_en';

interface SidebarMenuLinkProps {
  link: MenuItem;
  label: string;
  index: string;
}

const SidebarMenuLink = ({ link, label, index }: SidebarMenuLinkProps) => {
  const location = useLocation();
  const { lang } = useData();

  function getTooltipPropName(lang: string): IntlTooltipProps {
    return lang === 'en' ? 'tooltip_en' : 'tooltip_en';
  }

  const isActive = useMemo(
    () => link.route && location.pathname?.includes(link.route),
    [location.pathname, link.route],
  );

  return (
    <React.Fragment>
      <li className={isActive ? 'mm-active' : ''}>
        {/* {link[getTooltipPropName(lang)] && (
          <UncontrolledTooltip placement="top" target={`tooltip-${index}`}>
            {link[getTooltipPropName(lang)]}
          </UncontrolledTooltip>
        )} */}
        <Link to={link.route!} className={isActive ? 'active' : ''} id={`tooltip-${index}`}>
          <p>
            <i className={link.icon!} />
            <span className="font-size-15">{label}</span>
          </p>
          {link.badge_query && (
            <span className="badge badge-pill badge-primary float-right font-size-12">
              {link.badge_count}
            </span>
          )}
        </Link>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenuLink;
