import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, Label, FormGroup } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import images
import vector2 from '../../assets/images/vector2.png';
import logo from '../../assets/images/logo-laina-hub.png';

import { ILoginFormFields } from '../../store/types';
import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
// services
import {
  loginHubUser,
  getUsernamesFromEmail,
  loginMsAccount,
  selectAccountLogin,
} from '../../services';
import { printEDCLog } from '../../helpers';
import { MicrosoftButton } from '../../components/Common/MicrosoftButton/MicrosoftButton';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../constants/auth';
import DeleteUserModal from '../../components/DeleteUserModal';
import SelectUserModal from '../../components/SelectUserModal';

const cookies = new Cookies();

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

/**
 * Authentication/CoordinatorLogin component.
 *
 * @remarks
 * Page where data monitor can log in
 *
 * @component CoordinatorLogin
 * @category Page
 */
const CoordinatorLogin = (): JSX.Element => {
  const { dashTitle, setUserProjects } = useData();
  const { user, setUser, error, setError, clearState, setIsAuthenticated, setMagicCode } =
    useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [showAccounts, setShowAccounts] = useState(false);
  const { instance } = useMsal();
  const [isSelectUserModal, setIsSelectUserModal] = useState(false);
  const [userList, setUserList] = useState([]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const handleSearchUsernames = async (username: string) => {
    // if (!showAccounts) {
    const usersRes = await getUsernamesFromEmail(username);
    console.log(usersRes);
    if (usersRes && usersRes.data) {
      const usersData = usersRes.data.users;
      setUsers(usersData);
      if (usersData.length > 1) {
        setShowAccounts(true);
      }
      if (usersData.length == 1) {
        setUsername(usersData[0].username);
      }
      return usersData;
    }
    return [];
    // }
  };
  // handleValidSubmit
  async function handleValidSubmit(values: ILoginFormFields) {
    clearState();
    SetIsSubmitting(true);
    try {
      let res;
      if (username == '') {
        const usersFound = await handleSearchUsernames(values.username);
        if (usersFound.length > 1) {
          SetIsSubmitting(false);
          setError('Username or password is not correct');
          return;
        }
        if (usersFound.length == 1) {
          res = await loginHubUser(usersFound[0].username, values.password);
        } else if (usersFound.length == 0) {
          res = await loginHubUser(values.username, values.password);
        }
      } else {
        res = await loginHubUser(username, values.password);
      }
      printEDCLog('login success : loginHubUser', res);

      if (res && res.data) {
        const projects = res.data.projects?.map((project: any) => {
          const trial = project?.study;
          trial.url = `uat.${trial.study_code}.trial.lainaent.com`;
          return trial;
        });

        cookies.set('hubProjects', projects, { path: '/' });
        setUserProjects(projects);

        setIsAuthenticated(true);
        setUser({
          ...user,
          ...res.data,
        });
        setMagicCode(res.data.magicCode);
        console.log({
          _id: res.data._id,
          username: res.data.first_name + ' ' + res.data.last_name,
          magicCode: res.data.magicCode,
          hub_access_type: res.data.hub_access_type,
        });
        cookies.set(
          'hubAuthUser',
          {
            _id: res.data._id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            username: res.data.first_name + ' ' + res.data.last_name,
            hub_access_type: res.data.hub_access_type,
          },
          { path: '/' },
        );
        cookies.set('hubMagicCode', res.data.magicCode, { path: '/' });
        cookies.set('hubAuthToken', res.data.authToken, { path: '/' });
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.data) {
        setError(err.response.data.message ?? 'Username or password is not correct');
      }
    }
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  const handleClearState = () => {
    clearState();
  };

  const handleSelectUser = (u: any) => {
    setUsername(u.username);
  };

  const authMsHandler = async () => {
    const loginResponse = await instance.loginPopup(loginRequest);
    const response = await loginMsAccount(loginResponse.accessToken);
    const users = response.data.users;

    if (users.length === 1) {
      cookies.set('hubMagicCode', response.data.users[0].magicCode, { path: '/' });
      cookies.set('hubAuthToken', response.data.authToken, { path: '/' });
      setUser(response.data.users[0]);
      setIsAuthenticated(true);
    } else {
      setUserList(response.data.users);
      setIsSelectUserModal(true);
    }
  };

  const closeSelectUserModal = () => {
    setIsSelectUserModal(false);
  };

  const onSelectUserLogin = async (user: any) => {
    const response = await selectAccountLogin(user.username, user.email);
    cookies.set('hubMagicCode', response.data.user.magicCode, { path: '/' });
    cookies.set('hubAuthToken', response.data.authToken, { path: '/' });
    setUser(response.data.user);
    setIsAuthenticated(true);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <FontAwesomeIcon className="fa-2xl" icon={'home'} />
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">Study Coordinator Login</h5>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        username: '',
                        password: '',
                      }}
                      validationSchema={Yup.object().shape({
                        username: Yup.string().required('Enter Username/Email'),
                      })}
                      onSubmit={(values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ values, errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="form-group">
                            <Label for="username" className="form-label">
                              Username/Email
                            </Label>
                            <Field
                              name="username"
                              id="username"
                              type="input"
                              required
                              placeholder="Enter Username/Email"
                              className={
                                'form-control' +
                                (errors.username && touched.username ? ' is-invalid' : '')
                              }
                              onBlur={() => handleSearchUsernames(values.username)}
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          {showAccounts && (
                            <Card className="shadow-none">
                              <CardBody className="">
                                <div className="form-group">
                                  <Label for="username" className="form-label">
                                    Select an account
                                  </Label>
                                  <div>
                                    {users.map((user: any) => (
                                      <div
                                        key={user._id}
                                        className="custom-control custom-radio mb-3"
                                      >
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          name="username"
                                          id={'username-' + user?.username}
                                          value={user?.username}
                                          onChange={() => {
                                            handleSelectUser(user);
                                          }}
                                        />
                                        <label
                                          className={'custom-control-label'}
                                          htmlFor={'username-' + user?.username}
                                        >
                                          {user?.username}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          )}

                          <div className="form-group">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <Field
                              name="password"
                              id="password"
                              type="password"
                              required
                              placeholder="Enter Password"
                              className={
                                'form-control' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Log In
                              {isSubmitting && <Spinner className="spinner" />}
                            </button>
                          </div>
                          <div className="mt-1 d-flex justify-content-center">
                            <span>
                              <strong>OR</strong>s
                            </span>
                          </div>
                          <div className="mt-1 d-flex justify-content-center">
                            <MicrosoftButton onClick={authMsHandler} />
                          </div>
                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a className="login-notice" href={'https://prod.hub.lainaent.com/'}>
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}

                          <div className="mt-4 text-center">
                            <Link
                              to="/forgot-password"
                              className="text-muted"
                              onClick={handleClearState}
                            >
                              <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {userList.length > 0 && isSelectUserModal && (
          <SelectUserModal
            isOpen={userList.length > 0 && isSelectUserModal}
            closeModal={closeSelectUserModal}
            users={userList}
            onSelectUser={onSelectUserLogin}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CoordinatorLogin;
