import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Breadcrumb from '../../components/Common/Breadcrumb';
import DeleteUserModal from '../../components/DeleteUserModal';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getUsers, activateUser, deactivateUser, sendInvitationEmail } from '../../services';
import { User } from '../../constants/types';
import moment from 'moment';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
const pluralize = require('pluralize');

/**
 * Users Page component.
 *
 * @remarks
 * Users Page that lists users
 *
 * @component Users Page
 * @category Page
 */

const PageHeder = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
`;

const Users = (): JSX.Element => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Array<any>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);
  const [filters, setFilters] = useState({
    type: 'all',
    status: 'all',
    search: '',
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageLength, setPageLength] = useState(1);
  const [delUser, setDeleteUser] = useState<User>();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const getUsersData = async () => {
    const res = await getUsers();
    console.log('users data', res?.data);
    setUsers(res?.data?.users);
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const handleClickDeleteUser = (user: User) => {
    setDeleteUser(user);
    setIsShowDeleteModal(!isShowDeleteModal);
  };

  const handleResendInvitation = async (user: User) => {
    const res = await sendInvitationEmail(user._id);
    toastr.success('User has been successfully re-invited.', 'Success');
  };

  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const handleDeleteUser = async () => {
    if (delUser) {
      if (delUser.isDeleted) {
        await activateUser(delUser._id);
        toastr.success('User has been activated successfully.', 'Success');
      } else {
        await deactivateUser(delUser._id);
        toastr.success('User has been deactivated successfully.', 'Success');
      }

      setUsers((users) =>
        users.map((user: any) => {
          if (user._id === delUser._id) {
            return { ...user, isDeleted: !user.isDeleted };
          }
          return user;
        }),
      );
    }
    setIsShowDeleteModal(false);
  };

  const breadCrumbItems = [
    {
      link: '/users',
      text: 'User Management',
    },
    {
      link: '/users',
      text: 'Users List',
    },
  ];

  const handleView = (id: string) => {
    navigate(`/users/${id}`);
  };

  const handleChangeFilters = (e: any) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const filteredData = users.length
      ? users.filter((user) => {
          if (filters.type != 'all') {
            if (filters.type == 'internal' && !user.email?.endsWith('@protovate.com')) {
              return false;
            }
            if (
              filters.type == 'external' &&
              user.email &&
              user.email?.endsWith('@protovate.com')
            ) {
              return false;
            }
          }
          if (filters.status != 'all') {
            if (filters.status == 'active' && user.isDeleted) {
              return false;
            }
            if (filters.status == 'inactive' && !user.isDeleted) {
              return false;
            }
          }
          if (
            user.first_name?.includes(filters.search) ||
            user.last_name?.includes(filters.search) ||
            user.username?.includes(filters.search) ||
            user.email?.includes(filters.search)
          ) {
            return true;
          }
          return false;
        })
      : [];
    setFilteredUsers(filteredData);
    setPage(1);
    setPageLength(filteredData.length < perPage ? 1 : Math.ceil(filteredData.length / perPage));
  }, [filters, users, perPage]);

  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Involved in',
        field: 'involvedIn',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Last active on',
        field: 'updatedAt',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 150,
      },
    ],
  };

  const gotoPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const gotoNextPage = () => {
    if (page < pageLength) {
      setPage(page + 1);
    }
  };

  const renderPagination = () => {
    const items = [];
    const renderTop = Math.ceil(page / 8) * 8;
    for (let i = renderTop - 7; i <= Math.min(renderTop, pageLength); i++) {
      items.push(
        <PaginationItem active={page == i} onClick={() => setPage(i)}>
          <PaginationLink href="#">{i}</PaginationLink>
        </PaginationItem>,
      );
    }

    return (
      <Pagination aria-label="Page navigation example">
        <PaginationItem>
          <PaginationLink previous href="#" onClick={() => gotoPrevPage()}>
            Previous
          </PaginationLink>
        </PaginationItem>
        {items}
        <PaginationItem>
          <PaginationLink next href="#" onClick={() => gotoNextPage()}>
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        {/* <h3>Users List</h3> */}
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <PageHeder>
                  <Button color="primary" onClick={() => navigate('/users/create')}>
                    Add New User
                  </Button>
                </PageHeder>
                <div className="row">
                  <Col lg="12">
                    <Label className="form-label ">
                      Show entries
                      <select
                        name="perPage"
                        onChange={(e: any) => setPerPage(e.target.value)}
                        value={perPage}
                        className="custom-select custom-select-sm form-control form-control-sm"
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={10000}>All</option>
                      </select>
                    </Label>
                    <Label className="form-label ml-1">
                      <input
                        className="form-control form-control-sm"
                        name="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={filters.search}
                        onChange={handleChangeFilters}
                      />
                    </Label>
                    {/* <Label className="form-label users-type-select">
                    Select Type
                    <select
                      name="type"
                      onChange={handleChangeFilters}
                      value={filters.type}
                      className="custom-select custom-select-sm form-control form-control-sm"
                    >
                      <option value="all">All</option>
                      <option value="internal">Internal Users</option>
                      <option value="external">External Users</option>
                    </select>
                  </Label>
                  <Label className="form-label users-status-select">
                    Select Status
                    <select
                      name="status"
                      onChange={handleChangeFilters}
                      value={filters.status}
                      className="custom-select custom-select-sm form-control form-control-sm"
                    >
                      <option value="all">All</option>
                      <option value="active">Active Users</option>
                      <option value="inactive">Deactivated Users</option>
                    </select>
                  </Label> */}
                  </Col>
                  {/* <MDBDataTable className="w-100 usersTable" hover bordered data={data} /> */}
                  <table className="table sortable-table usersTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Involved in</th>
                        <th>Last active on</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers
                        .slice((page - 1) * perPage, page * perPage)
                        .map((user: any) => {
                          return (
                            <tr key={user._id} className="table-light">
                              <td>
                                <span className={user.isDeleted ? 'red' : 'green'}>
                                  {user.first_name + ' ' + user.last_name}
                                </span>
                                {user.email?.includes('@protovate.com') && (
                                  <span className="badge badge-pill badge-secondary font-size-12 ml-2">
                                    Internal
                                  </span>
                                )}
                              </td>
                              <td>
                                <span className={user.isDeleted ? 'red' : 'green'}>
                                  {user?.username}
                                </span>
                              </td>
                              <td>
                                <span className={user.isDeleted ? 'red' : 'green'}>
                                  {user?.email}
                                </span>
                              </td>
                              <td>
                                <span className={user.isDeleted ? 'red' : 'green'}>
                                  {user?.projects?.length
                                    ? user?.projects?.length +
                                      ' ' +
                                      pluralize('study', user?.projects?.length)
                                    : 'None'}
                                </span>
                              </td>
                              <td>
                                <span className={user.isDeleted ? 'red' : 'green'}>
                                  {user?.lastLogin?.time
                                    ? moment(user?.lastLogin?.time).format('YYYY-MM-DD hh:mm:ss')
                                    : 'Never logged in'}
                                </span>
                              </td>
                              <td>
                                <div>
                                  <Button
                                    color="primary"
                                    className="mr-1 mb-1 btn-sm"
                                    id={`view-btn-${user._id}`}
                                    onClick={() => handleView(user._id)}
                                  >
                                    Edit Details
                                  </Button>
                                  <Button
                                    color={user.isDeleted ? 'success' : 'danger'}
                                    className="mr-1 mb-1 btn-sm"
                                    id={`delete-btn-${user._id}`}
                                    onClick={() => handleClickDeleteUser(user)}
                                  >
                                    {user.isDeleted ? 'Activate User' : 'Deactivate User'}
                                  </Button>
                                  {!user?.lastLogin?.time && (
                                    <Button
                                      color={'warning'}
                                      className="mr-1 mb-1 btn-sm"
                                      id={`resend-btn-${user._id}`}
                                      onClick={() => handleResendInvitation(user)}
                                    >
                                      Resend Invitation
                                    </Button>
                                  )}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`view-btn-${user._id}`}
                                  >
                                    View User
                                  </UncontrolledTooltip>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`delete-btn-${user._id}`}
                                  >
                                    {user.isDeleted ? 'Activate' : 'Deactivate'} User
                                  </UncontrolledTooltip>
                                  {!user?.lastLogin?.time && (
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`resend-btn-${user._id}`}
                                    >
                                      Resend Invitation Email
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <Row className="justify-content-between">
                  <label>Showing 1 to 10 of {filteredUsers.length} entries</label>
                  {renderPagination()}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <DeleteUserModal
        isOpen={isShowDeleteModal}
        isDelete={!delUser?.isDeleted}
        closeModal={closeDeleteModal}
        confirmDelete={handleDeleteUser}
      />
    </div>
  );
};

export default Users;
