import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function DuplicateStudyWarningModal({ isOpen, toggleModal }: any) {
  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Duplicate Trial Selected</ModalHeader>
        <ModalBody>
          <div>Repeated Trials are selected. Please select each trial single time.</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DuplicateStudyWarningModal;
