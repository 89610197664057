import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type Props = {
  isOpen: boolean;
  isDelete: boolean;
  closeModal: () => void;
  confirmDelete: () => void;
};
function DeleteUserModal({ isOpen, isDelete, closeModal, confirmDelete }: Props) {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          {isDelete ? 'Deactivate' : 'Activate'} User
        </ModalHeader>
        <ModalBody>
          <div>Are you sure you want to {isDelete ? 'deactivate' : 'activate'} this user?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmDelete()}>
            Yes
          </Button>
          &nbsp;
          <Button color="secondary" onClick={() => closeModal()}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteUserModal;
