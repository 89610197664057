import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function SelectUserModal({ isOpen, closeModal, users, onSelectUser }: any) {
  return (
    <div>
      <Modal centered isOpen={isOpen}>
        <ModalHeader>Select an account to login</ModalHeader>
        <ModalBody>
          <div>There are {(users ?? []).length} accounts  available with your email ID. Select one of them to proceed.</div>
          <div>
            {(users ?? []).map((user: any, i: number) => {
              return (
                <button
                  className="btn btn-login btn-block waves-effect waves-light save-btn"
                  type="submit"
                  key={i}
                  onClick={() => {
                    onSelectUser(user);
                  }}
                >
                  {user.username}
                </button>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => closeModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SelectUserModal;
