import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { getRoles } from '../../services';
import Breadcrumb from '../../components/Common/Breadcrumb';
import DeleteRoleModal from '../../components/DeleteRoleModal';
import { useNavigate } from 'react-router-dom';

/**
 * Roles Page component.
 *
 * @remarks
 * Roles Page that lists all roles
 *
 * @component Roles Page
 * @category Page
 */

const PageHeder = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
`;

const Roles = (): JSX.Element => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [hubRole, setHubRole] = useState<any>();
  const [deleteId, setDeleteId] = useState<string>();
  const breadCrumbItems = [
    {
      link: '/roles',
      text: 'Role Management',
    },
    {
      link: '/roles',
      text: 'Role List',
    },
  ];

  const deleteToggleModal = (id: string) => {
    setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const handleEdit = (data: any) => {
    navigate(`/roles/${data._id}`, { state: hubRole });
  };

  const handleAdd = () => {
    navigate('/roles/create', { state: hubRole });
  };

  const getRoleGroup = async () => {
    const hubRoleRes = await getRoles();
    setHubRole(hubRoleRes.data);
  };

  useEffect(() => {
    getRoleGroup();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid className="container-page">
          <Breadcrumb items={breadCrumbItems} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PageHeder>
                    <Button color="primary" onClick={() => handleAdd()}>
                      Add New Role
                    </Button>
                  </PageHeder>
                  <div className="row">
                    <table className="table sortable-table">
                      <thead>
                        <tr>
                          <th>Role Name</th>
                          <th>Role Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hubRole?.roles?.map((data: any) => {
                          return (
                            <tr key={data._id} className="table-light">
                              <td>{data.name}</td>
                              <td>{data.description}</td>
                              <td>
                                <UncontrolledTooltip placement="top" target="edit-btn">
                                  Edit Role
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="top" target="delete-btn">
                                  Delete Role
                                </UncontrolledTooltip>
                                <Button
                                  color="primary"
                                  id="edit-btn"
                                  className="mr-1 mb-1 btn-sm"
                                  onClick={() => handleEdit(data)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="secondary"
                                  className="mr-1 mb-1 btn-sm"
                                  id="delete-btn"
                                  onClick={() => deleteToggleModal(data._id)}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteRoleModal
        modal={deleteModal}
        id={deleteId}
        deleteToggleModal={deleteToggleModal}
        getRoleGroup={getRoleGroup}
      />
    </>
  );
};

export default Roles;
