import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

type Props = {
  items: Array<{ link: string; text: string }>;
};

const Breadcrumb = ({ items }: Props) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{items[items.length - 1].text}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {items.map((item, index) => {
                return (
                  <BreadcrumbItem key={'breadcrumb' + index}>
                    {items.length - 1 !== index ? (
                      <Link to={item.link}>{item.text}</Link>
                    ) : (
                      item.text
                    )}
                  </BreadcrumbItem>
                );
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
