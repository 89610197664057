import React, { useEffect, useMemo, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';

import { MenuItem } from '../../constants/types';
import SidebarMenuLink from './SidebarMenuLink';
import { useData } from '../../context/DataContext';

type IntlTooltipProps = 'tooltip_en';
type IntlTitleProps = 'title_en';
type IntlSubtitleProps = 'subtitle_en';

interface SidebarMenuProps {
  menu: MenuItem;
  index: number;
  collapse: boolean;
  onCollapse: (menuItem: MenuItem | undefined) => void;
}

const SidebarMenu = ({ menu, index, collapse, onCollapse }: SidebarMenuProps) => {
  const location = useLocation();
  const { lang } = useData();

  const [isCollapsed, setIsCollapsed] = useState(true);

  function getTooltipPropName(lang: string): IntlTooltipProps {
    return lang === 'en' ? 'tooltip_en' : 'tooltip_en';
  }

  function getTitlePropName(lang: string): IntlTitleProps {
    return lang === 'en' ? 'title_en' : 'title_en';
  }

  function getSubtitlePropName(lang: string): IntlSubtitleProps {
    return lang === 'en' ? 'subtitle_en' : 'subtitle_en';
  }

  const isActive = useMemo(
    () =>
      !!menu.children.find(
        (item: MenuItem) => item.route && location.pathname.includes(item.route),
      ),
    [location.pathname, menu.children],
  );

  useEffect(() => {
    setIsCollapsed(collapse);
  }, [collapse]);

  const onToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    onCollapse(isCollapsed ? menu : undefined);
  };

  return (
    <React.Fragment>
      <li className={!isCollapsed ? 'mm-active' : ''}>
        {/* {menu[getTooltipPropName(lang)] && (
          <UncontrolledTooltip placement="top" target={`tooltip-${index}`}>
            {menu[getTooltipPropName(lang)]}
          </UncontrolledTooltip>
        )} */}
        <Link
          to="#"
          className={`has-arrow waves-effect ${isActive ? 'mm-active' : ''}`}
          id={`tooltip-${index}`}
          onClick={onToggleCollapse}
        >
          <i className={menu.icon!} />
          <span className="font-size-15">{menu[getTitlePropName(lang)]}</span>
        </Link>
        <ul
          className={`sub-menu mm-collapse ${isCollapsed ? '' : 'mm-show'}`}
          aria-expanded="false"
        >
          {menu.children.map((menuLink, i) => (
            <SidebarMenuLink
              key={i}
              link={menuLink}
              label={menuLink[getSubtitlePropName(lang)] || ''}
              index={`${index}-${i}`}
            />
          ))}
          <hr className="sub-menu-bottom-border" />
        </ul>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenu;
