import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { useData } from '../context/DataContext';

/**
 * NonAuthLayout component.
 *
 * @remarks
 * Layout for guest users
 *
 * @component NonAuthLayout
 * @category Component
 */
const NonAuthLayout = () => {
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};

export default NonAuthLayout;
