import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
  Row,
  Col,
} from 'reactstrap';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import { useAuth } from '../context/AuthContext';
import { authChangePassword } from '../services';
import { AxiosError } from 'axios';
import toastr from 'toastr';

const cookies = new Cookies();

const ErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

function ChangePasswordModal({ isOpen, isLoggedinUser, toggleModal, changePassword }: any) {
  const { user, setUser, clearState, setIsAuthenticated } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const empty_validation_msg = 'This field is required and may not be left empty.';

  const handleChangePassword = async () => {
    setError('');
    if (isLoggedinUser) {
      if (values.currentPassword === '') {
        setErrors((e: any) => ({
          ...e,
          currentPassword: empty_validation_msg,
        }));
        return;
      }
    }
    if (values.newPassword === '' || values.confirmPassword === '') {
      if (values.newPassword === '') {
        setErrors((e: any) => ({
          ...e,
          newPassword: empty_validation_msg,
        }));
      }
      if (values.confirmPassword === '') {
        setErrors((e: any) => ({
          ...e,
          confirmPassword: empty_validation_msg,
        }));
      }
      return;
    }
    if (values.newPassword !== values.confirmPassword) {
      setErrors((e: any) => ({
        ...e,
        confirmPassword: 'Confirm password does not match',
      }));
      return;
    }

    if (isLoggedinUser) {
      authChangePassword(values.currentPassword, values.newPassword)
        .then((resp: any) => {
          console.log(resp);
          if (resp.data?.success) {
            toastr.success('Your password has been successfully changed.', 'Success');
          } else {
            toastr.error(resp.data?.message, 'Failure');
          }
        })
        .catch((error) => {
          const err = error as AxiosError;
          if (err.response && err.response.data) {
            setError(err.response.data.message ?? 'Change password failed');
          }
          console.log(error);
        });
    } else {
      try {
        await changePassword(values.newPassword);
        toggleModal();
        setValues({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      } catch (e: any) {
        setError(e?.response?.data?.message);
      }
    }
  };
  console.log(errors);

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Change Password</ModalHeader>
        <ModalBody>
          <div>
            {isLoggedinUser && (
              <Row>
                <Col sm={4}>
                  <label className="col-form-label">Current Password</label>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <input
                      className={
                        errors.currentPassword ? 'is-invalid form-control' : 'form-control'
                      }
                      type="password"
                      name={'currentPassword'}
                      value={values['currentPassword']}
                      onChange={(e: any) => {
                        setValues({ ...values, currentPassword: e.target.value });
                        if (e.target.value !== '') setErrors({ ...errors, currentPassword: '' });
                      }}
                      onBlur={() => {
                        if (values.currentPassword === '') {
                          setErrors((e: any) => ({
                            ...e,
                            currentPassword: empty_validation_msg,
                          }));
                        }
                      }}
                    />
                    {errors.currentPassword && (
                      <span className="validation-error">{errors.currentPassword}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={4}>
                <label className="col-form-label">New Password</label>
              </Col>
              <Col sm={8}>
                <FormGroup>
                  <input
                    className={errors.newPassword ? 'is-invalid form-control' : 'form-control'}
                    type="password"
                    name={'newPassword'}
                    value={values['newPassword']}
                    onChange={(e: any) => {
                      setValues({ ...values, newPassword: e.target.value });
                      if (e.target.value !== '') setErrors({ ...errors, newPassword: '' });
                    }}
                    onBlur={() => {
                      if (values.newPassword === '') {
                        setErrors((e: any) => ({
                          ...e,
                          newPassword: empty_validation_msg,
                        }));
                      }
                    }}
                  />
                  {errors.newPassword && (
                    <span className="validation-error">{errors.newPassword}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <label className="col-form-label">Confirm Password</label>
              </Col>
              <Col sm={8}>
                <FormGroup>
                  <input
                    className={errors.confirmPassword ? 'is-invalid form-control' : 'form-control'}
                    type="password"
                    name={'confirmPassword'}
                    value={values['confirmPassword']}
                    onChange={(e: any) => {
                      setValues({ ...values, confirmPassword: e.target.value });
                      if (e.target.value !== '') setErrors({ ...errors, confirmPassword: '' });
                    }}
                    onBlur={() => {
                      if (values.confirmPassword === '') {
                        setErrors((e: any) => ({
                          ...e,
                          confirmPassword: empty_validation_msg,
                        }));
                      }
                    }}
                  />
                  {errors.confirmPassword && (
                    <span className="validation-error">{errors.confirmPassword}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {error ? <ErrorMsg>{error}</ErrorMsg> : null}
            <Row className="mt-2 mb-2">
              <Col sm={4}></Col>
              <Col>
                <Button
                  className="save-btn"
                  type="button"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => handleChangePassword()}
                >
                  Change Password
                  {isSubmitting && <Spinner className="spinner" />}
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
