import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Col, Card, CardBody, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import styled from 'styled-components';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { getStudies, getSites, createSite, updateSite } from '../../services';
import toastr from 'toastr';
import { useData } from '../../context/DataContext';

const validationSchema = Yup.object().shape({
  site_id: Yup.string().required('Site ID is required'),
  site_name: Yup.string().required('Site Name is required'),
  address: Yup.string().required('Address is required'),
  contact_name: Yup.string().required('Contact Name is required'),
  contact_email: Yup.string().required('Contact Email is required'),
});

const ErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

function SiteAddEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const { allSites, setAllSites } = useData();
  const siteId = params.id as string;
  const studyId = params.studyId as string;
  const [study, setStudy] = useState<any>();
  const [site, setSite] = useState<any>();
  const [error, setError] = useState('');

  const breadCrumbItems = [
    {
      link: '/studies',
      text: 'Study Management',
    },
    {
      link: `/studies/${studyId}`,
      text: `Site Locations Of ${study?.study_name ?? ''}`,
    },
    {
      link: '/studies',
      text: 'Add Site Location',
    },
  ];

  const initialValue = {
    site_id: site?.site_id ?? '',
    site_name: site?.site_name ?? '',
    site_type: site?.site_type ?? 'uat',
    address: site?.address ?? '',
    contact_name: site?.contacts?.name ?? '',
    contact_email: site?.contacts?.email ?? '',
  };

  useEffect(() => {
    if (allSites.length) {
      const currentSite = allSites.find((site: any) => site._id == siteId);
      setSite(currentSite);
    } else {
      getSitesData();
    }
  }, []);

  const getSitesData = async () => {
    const studiesRes = await getStudies();
    if (studiesRes?.data?.studies) {
      const currentStudy = studiesRes.data.studies?.find((study: any) => study._id == studyId);
      setStudy(currentStudy);
      const sitesRes = await getSites();
      if (sitesRes?.data?.sites && siteId) {
        const currentSite = sitesRes.data.sites.find((site: any) => site._id == siteId);
        setSite(currentSite);
        setAllSites(sitesRes?.data?.sites);
      }
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    try {
      setError('');
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
        actions.setFieldError('contact_email', 'Email format is invalid.');
        return;
      }
      if (siteId) {
        const updatePayload = {
          ...values,
          study: studyId,
          contacts: {
            name: values.contact_name,
            email: values.contact_email,
          },
        };
        let key: keyof typeof updatePayload;
        for (key in updatePayload) {
          console.log(typeof updatePayload[key], typeof site[key]);
          if (updatePayload[key] == site[key]) {
            delete updatePayload[key];
          }
        }
        delete updatePayload['contact_name'];
        delete updatePayload['contact_email'];
        await updateSite(siteId, updatePayload);
        toastr.success('Site has been successfully updated.', 'Success');
      } else {
        await createSite({
          ...values,
          study: studyId,
          contacts: {
            name: values.contact_name,
            email: values.contact_email,
          },
        });
        toastr.success('Site has been successfully created.', 'Success');
      }
      navigate(`/studies/${studyId}`);
    } catch (e: any) {
      setError(e?.response?.data?.message);
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values, handleChange }) => {
                    return (
                      <>
                        <Form>
                          <div className="login-form form-group">
                            <FormGroup>
                              <div>
                                <Label for="site_id">
                                  Site ID <span>*</span>
                                </Label>
                                <Field
                                  name="site_id"
                                  id="site_id"
                                  type="text"
                                  value={values.site_id}
                                  placeholder="Enter Site ID"
                                  className={`form-control ${
                                    touched.site_id && errors.site_id ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.site_id && touched.site_id ? (
                                <ErrorMsg>{errors.site_id}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="site_name">
                                  Site Name <span>*</span>
                                </Label>
                                <Field
                                  name="site_name"
                                  id="site_name"
                                  type="text"
                                  value={values.site_name}
                                  placeholder="Enter Site Name"
                                  className={`form-control ${
                                    touched.site_name && errors.site_name ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.site_name && touched.site_name ? (
                                <ErrorMsg>{errors.site_name}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <Label for="site_type">
                                Site Type <span>*</span>
                              </Label>
                              <div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="site_type"
                                    id="site_type_uat"
                                    value="uat"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                    defaultChecked={values.site_type == 'uat'}
                                  />
                                  <label className="form-check-label" htmlFor="site_type_uat">
                                    UAT Site
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="site_type"
                                    id="site_type_prod"
                                    value="prod"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                    defaultChecked={values.site_type == 'prod'}
                                  />
                                  <label className="form-check-label" htmlFor="site_type_prod">
                                    PROD Site
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="address">
                                  Address <span>*</span>
                                </Label>
                                <Field
                                  name="address"
                                  id="address"
                                  type="text"
                                  component="textarea"
                                  value={values.address}
                                  placeholder="Enter Address"
                                  className={`form-control ${
                                    touched.address && errors.address ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.address && touched.address ? (
                                <ErrorMsg>{errors.address}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="contact_name">
                                  Contact Name: <span>*</span>
                                </Label>
                                <Field
                                  name="contact_name"
                                  id="contact_name"
                                  type="text"
                                  value={values.contact_name}
                                  placeholder="Enter Contact Name"
                                  className={`form-control ${
                                    touched.contact_name && errors.contact_name ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.contact_name && touched.contact_name ? (
                                <ErrorMsg>{errors.contact_name}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="contact_email">
                                  Contact Email <span>*</span>
                                </Label>
                                <Field
                                  name="contact_email"
                                  type="text"
                                  id="contact_email"
                                  value={values.contact_email}
                                  placeholder="Enter Contact Email"
                                  className={`form-control ${
                                    touched.contact_email && errors.contact_email
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.contact_email && touched.contact_email ? (
                                <ErrorMsg>{errors.contact_email}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                          </div>
                          {error ? <ErrorMsg>{error}</ErrorMsg> : null}
                          <Button color="secondary" onClick={() => navigate(`/studies/${studyId}`)}>
                            Cancel
                          </Button>{' '}
                          <Button color="success" type="submit">
                            {siteId ? 'Update' : 'Save'}
                          </Button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SiteAddEdit;
