import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ActivateUserModal({ isOpen, toggleModal, toggleUserStatus, isDeleted, userId }: any) {
  const handleUpdateStatus = () => {
    toggleUserStatus();
    toggleModal();
  };
  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isDeleted ? 'Activate' : 'Deactivate'} User</ModalHeader>
        <ModalBody>
          <div>Are you sure you want to {isDeleted ? 'activate' : 'deactivate'} this user?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateStatus}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ActivateUserModal;
