import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const apiBaseUrl = 'https://uat.hub.lainaent.com/api/v1/hub';

export function authChangePassword(oldPassword: string, newPassword: string) {
  return axios.post(
    `${apiBaseUrl}/auth/changePassword`,
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function resetPasswordRequest(username: string) {
  return axios.post(`${apiBaseUrl}/reset-password-request`, {
    username,
  });
}

export function resetPasswordWithCode(
  username: string,
  confirmationCode: string,
  newPassword: string,
) {
  return axios.post(`${apiBaseUrl}/reset-password-with-code`, {
    username,
    confirmationCode,
    newPassword,
  });
}

export function getUsernamesFromEmail(email: string) {
  return axios.get(`${apiBaseUrl}/auth/get-usernames-with-email?email=${email}`);
}

export function validateParticipant(participantId: string) {
  return axios.get(`${apiBaseUrl}/validateParticipant/${participantId}`);
}

export function loginHubUser(username: string, password: string) {
  return axios.post(`${apiBaseUrl}/login`, {
    username,
    password,
  });
}

export function loginMsAccount(accessToken: string) {
  return axios.post(`${apiBaseUrl}/auth-ms-account`, {
    accessToken,
  });
}

export function selectAccountLogin(username: string, email: string) {
  return axios.post(`${apiBaseUrl}/select-account-login`, {
    username,
    email,
  });
}

export function setPassword(id: string, newPassword: string) {
  return axios.post(`${apiBaseUrl}/setPassword/${id}`, {
    newPassword,
  });
}

function authToken() {
  return cookies.get('hubAuthToken');
}

export function changeUserPassword(id: string, newPassword: string) {
  return axios.post(
    `${apiBaseUrl}/users/changePassword/${id}`,
    {
      newPassword,
    },
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function getRoleGroups() {
  return axios.get(`${apiBaseUrl}/role/getGroups`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getRoles() {
  return axios.get(`${apiBaseUrl}/role`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function createHubRole(data: any) {
  return axios.post(`${apiBaseUrl}/role`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function updateHubRole(data: any, roleId: any) {
  return axios.put(`${apiBaseUrl}/role/${roleId.id}`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function deleteHubRole(id: any) {
  return axios.delete(`${apiBaseUrl}/role/${id}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getUsers() {
  return axios.get(`${apiBaseUrl}/users`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getUser(id: string) {
  return axios.get(`${apiBaseUrl}/users/${id}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getMe() {
  return axios.get(`${apiBaseUrl}/auth/me`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getUsersActivity(id: string) {
  return axios.get(`${apiBaseUrl}/users/activity/${id}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getStudies() {
  return axios.get(`${apiBaseUrl}/studies`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function createUser(data: any) {
  return axios.post(`${apiBaseUrl}/users`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function updateUser(id: string, data: any) {
  return axios.put(`${apiBaseUrl}/users/${id}`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function deactivateUser(id: string) {
  return axios.delete(`${apiBaseUrl}/users/${id}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function activateUser(id: string) {
  return axios.put(
    `${apiBaseUrl}/users/activate/${id}`,
    {},
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function sendEmail(id: string, data: any) {
  return axios.post(`${apiBaseUrl}/users/email/${id}`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function sendInvitationEmail(id: string) {
  return axios.post(
    `${apiBaseUrl}/users/sendInvitationEmail/${id}`,
    {},
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function sendResetPasswordEmail(id: string) {
  return axios.post(
    `${apiBaseUrl}/users/send-reset-password-email/${id}`,
    {},
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function getResetPasswordInfo(token: string) {
  return axios.get(`${apiBaseUrl}/auth/get-reset-password-info/${token}`);
}

export function getSites() {
  return axios.get(`${apiBaseUrl}/sites`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function createSite(data: any) {
  return axios.post(`${apiBaseUrl}/sites`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function updateSite(id: string, data: any) {
  return axios.put(`${apiBaseUrl}/sites/${id}`, data, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function deleteSite(id: string) {
  return axios.delete(`${apiBaseUrl}/sites/${id}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}
