import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { getStudies } from '../../services';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';

/**
 * Studies Page component.
 *
 * @remarks
 * Studies Page that lists all Studies
 *
 * @component Studies Page
 * @category Page
 */

const Studies = (): JSX.Element => {
  const navigate = useNavigate();
  const [studies, setStudies] = useState<Array<any>>();
  const breadCrumbItems = [
    {
      link: '/studies',
      text: 'Study Management',
    },
    {
      link: '/studies',
      text: 'Study List',
    },
  ];

  const handleEdit = (study: any) => {
    navigate(`/studies/${study._id}`);
  };

  const getStudiesData = async () => {
    const studiesRes = await getStudies();
    if (studiesRes?.data?.studies) {
      setStudies(studiesRes.data.studies);
    }
  };

  useEffect(() => {
    getStudiesData();
  }, []);

  const data = {
    columns: [
      {
        label: 'Study Name',
        field: 'study_name',
        sort: 'asc',
      },
      {
        label: 'Study Code',
        field: 'study_code',
        sort: 'asc',
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 150,
      },
    ],
    rows: studies?.map((study: any) => {
      return {
        study_name: study.study_name,
        study_code: <span style={{ color: study.study_color }}>{study.study_code}</span>,
        actions: (
          <div>
            <Button
              color="primary"
              id={`manage-locations-btn`}
              className="mr-1 mb-1 btn-sm"
              onClick={() => handleEdit(study)}
            >
              Manage Site Locations
            </Button>
            <UncontrolledTooltip placement="top" target={`manage-locations-btn`}>
              Manage Site Locations
            </UncontrolledTooltip>
          </div>
        ),
      };
    }),
  };

  return (
    <>
      <div className="page-content">
        <Container fluid className="container-page">
          <Breadcrumb items={breadCrumbItems} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="row">
                    <MDBDataTable className="w-100" hover bordered data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Studies;
